import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "About",
  "route": "/about"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About Smartlike`}</h1>
    <p>{`Smartlike builds a free non-profit decentralized donation processor with a focus on `}<strong parentName="p">{`efficiency`}</strong>{`, `}<strong parentName="p">{`privacy`}</strong>{` and `}<strong parentName="p">{`freedom`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`zero commission, high bandwidth micro-donations`}</li>
      <li parentName="ul">{`no registration, no private data collection`}</li>
      <li parentName="ul">{`decentralized moderation with diverse policies`}</li>
    </ul>
    <p>{`The service employs a hybrid from `}<strong parentName="p">{`trusted`}</strong>{` relationships between creators and their audience for private and secure end-to-end payments and `}<strong parentName="p">{`trust-less`}</strong>{` horizontally scalable public ledger technology for transaction processing without cryptocurrency.`}</p>
    <p>{`Our `}<strong parentName="p">{`mission`}</strong>{` is to help decentralize the Internet, develop `}<strong parentName="p">{`common good`}</strong>{` technologies for people and businesses to thrive in direct cooperation.`}</p>
    <p>{`The code will be released as FLOSS, the network to be governed by a meritocratic user consensus. Come and give the system a `}<a parentName="p" {...{
        "href": "https://smartlike.org"
      }}>{`test`}</a>{`. It is still in development, but is fully functional, transparent, financially decentralized and secure by design.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      